.left_sidebar {
    height: 100vh;
}

.demo-logo-vertical {
    margin-top: 90px;
}

.site-card-border-less-wrapper {
    margin: 0 auto;
    width: 600px;
}

.site-card-border-less-wrapper h1 {
    text-align: center;
}

.upload_btn {
    text-align: center;
}

.login-form-side {
    position: absolute;
    top: 45%;
    left: 50%;
    width: 500px;
    height: 400px;
    transform: translate(-50%, -50%);
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 5px;
}

.login_form_heading {
    margin-top: 10px;
}

.login-button-color{
    margin-top: 20px;
}

.ant-table-tbody .ant-table-row-level-1 {
    display: none;
}